import { reactive } from 'vue'
import { AxiosResponse } from 'axios'
import axios from '@/config/axiosConfig'
import i18n from '@/i18n'
import Swal from 'sweetalert2'




const state = reactive({
    selectedSection: 'personalData',
    account: {
        settingsEmailForm: {
            showSuccess: false,
            showError: false,
            errorMessage: '',
            newEmail: '',
            changeEmail: false,
        },
        settingsPasswordForm: {
            showSuccess: false,
            showError: false,
            errorMessage: '',
            password: '',
            newPassword: '',
            changePassword: false,
        },
        settingsNotificationForm: {
            notifByEmail: false,
            notifByPush: false,
            updatingNotifications: false,
        },
        profileEditFormData: {
            loadingPicture: false,
            picture: '',
            nickname: '',
            gender: {
                id: '',
                value: '',
            },
            genders: {
                male: {
                    id: 'M',
                    value: 'Hombre',
                },
                female: {
                    id: 'W',
                    value: 'Mujer',
                },
            },
            province: {
                id: '',
                value: '',
            },
            postalCode: '',
            birthday: {
                day: '',
                month: '',
                year: '',
            },
            showInvalidDate: false,
            showSuccess: false,
            showError: false,
            errorMessage: '',
        },
    },
    details: {
        // personalDataForm: {
        //     name: '',
        //     surname1: '',
        //     surname2: '',
        //     identityDocType: {
        //         id: '',
        //         value: '',
        //     },
        //     identityDocNum: '',
        //     address: '',
        //     postalCode: '',
        //     locality: '',
        //     province: {
        //         id: '',
        //         value: '',
        //     },
        //     residenceCountry: {
        //         id: '',
        //         value: '',
        //     },
        //     email: '',
        //     showSuccess: false,
        //     showError: false,
        //     errorMessage: '',
        // },
        personalDataForm: {
            name: '',
            surname1: '',
            surname2: '',
            gender: '',
            date: '',
            nickname: '',
            showSuccess: false,
            showError: false,
            errorMessage: '',
        },
        addressDataForm: {
            province: {
                id: '',
                value: '',
            },
            postalCode: '',
            address: '',
            locality: '',
            identityDocType: {
                id: '',
                value: '',
            },
            identityDocNum: '',
            residenceCountry: {
                id: '',
                value: '',
            },
            showSuccess: false,
            showError: false,
            errorMessage: '',
        },
        identityDocTypes: {},
        IdentityDocTypes: 'identity_doc_type',
        provinces: {},
        Provinces: {
            id: 'provinces',
            filter: 'es',
        },
        countries: {},
        Countries: 'countries',
    },
    uniqueValidationFormData: {
        emailData: {
            email: '',
            validationCode: '',
            input: {
                showError: false,
                errorCode: ''
            },
            code: {
                showError: false,
                errorCode: ''
            }
        },
        mobileData: {
            prefix: '',
            number: '',
            validationCode: '',
            input: {
                showError: false,
                errorCode: ''
            },
            code: {
                showError: false,
                errorCode: ''
            }
        }
    },
    phone: {
        addMobileFormData: {
            number: '',
            prefix: '',
            showSuccess: false,
            showError: false,
            errorMessage: '',
        },
        newMobileFormData: {
            mobile: '',
            showSuccess: false,
            showError: false,
            errorMessage: '',
        },
        mobileValidationFormData: {
            mobileValidationCode: '',
            showSuccess: false,
            showError: false,
            errorMessage: '',
        },
    },
    cancelAcccount: {
        cancelAccountForm: {
            showError: false,
        },
    },
    ranking: {
        ranking: [],
        topRanking: [],
    },
    signupStep2FormData: {
        nickname: '',
        gender: {
            id: '',
            value: '',
        },
        genders: {
            male: {
                id: 'M',
                value: 'Hombre',
            },
            female: {
                id: 'W',
                value: 'Mujer',
            },
        },
        province: {
            id: '',
            value: '',
        },
        residenceCountry: {
            id: '',
            value: '',
        },
        postalCode: '',
        birthday: {
            day: '',
            month: '',
            year: '',
        },
        showInvalidDate: false,
        showSuccess: false,
        showError: false,
        errorMessage: '',
    },
    showProfileBox: false,
})

interface NewEmail {
    newemail: string
}
interface NewPassword {
    oldPassword: string
    newPassword: string
}
interface UpdateNotifications {
    notifByEmail: boolean
    notifByPush: boolean
}
interface PaymentSettings {
    nickname: ''
    bornDate: ''
    gender: ''
    name: ''
    surname1: ''
    surname2: ''
    identityDocType: {
        elementId: ''
    }
    identityDocNum: ''
    address: ''
    postalCode: ''
    locality: ''
    province: {
        elementId: ''
    }
    residenceCountry: {
        elementId: ''
    }
}

interface ValidateUniqueMobile {
    prefix: string,
    number: string
}

interface ValidateUniqueEmail {
    email: string
}

interface NewMobile {
    mobile: string
}

interface AddMobile {
    number: string,
    prefix: string
}

interface EditProfile {
    nickname: string
    gender: string
    province: {
        elementId: ''
    }
    bornDate: string
    postalCode: ''
    picture: ''
}

interface Image {
    file: string
}

const setShowProfileBox = (value: boolean) => {
    state.showProfileBox = value
}

const clearMyAccount = function () {
    state.account.settingsEmailForm.showSuccess = false
    state.account.settingsEmailForm.showError = false
    state.account.settingsEmailForm.errorMessage = ''
    state.account.settingsEmailForm.changeEmail = false

    state.account.settingsPasswordForm.showSuccess = false
    state.account.settingsPasswordForm.showError = false
    state.account.settingsPasswordForm.errorMessage = ''
    state.account.settingsPasswordForm.changePassword = false

    state.account.settingsNotificationForm.updatingNotifications = false

    state.account.profileEditFormData.loadingPicture = false
    state.account.profileEditFormData.showInvalidDate = false
    state.account.profileEditFormData.showSuccess = false
    state.account.profileEditFormData.showError = false
    state.account.profileEditFormData.errorMessage = ''
}
const clearContactDetails = function () {
    state.details.personalDataForm.showSuccess = false
    state.details.personalDataForm.showError = false
    state.details.personalDataForm.errorMessage = ''
}

const clearPhoneNumber = function () {
    state.phone.newMobileFormData.showSuccess = false
    state.phone.newMobileFormData.showError = false
    state.phone.newMobileFormData.errorMessage = ''

    state.phone.mobileValidationFormData.showSuccess = false
    state.phone.mobileValidationFormData.showError = false
    state.phone.mobileValidationFormData.errorMessage = ''
    state.phone.mobileValidationFormData.mobileValidationCode = ''
}

const clearCancelAccount = function () {
    state.cancelAcccount.cancelAccountForm.showError = false
}

const ChangeEmail = async function (data: NewEmail) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/tester/newEmail`, data)
    } catch (e: any) {
        if (e.response.data.error == 'duplicate element') {
            state.account.settingsEmailForm.errorMessage = i18n.global.t(
                'Client_settings_settingscontroller_another_acc'
            )
        } else {
            state.account.settingsEmailForm.errorMessage = i18n.global.t(
                'Error_Try_Again'
            )
        }
        state.account.settingsEmailForm.showSuccess = false
        state.account.settingsEmailForm.showError = true
        return false
    }

    if (response?.status != 200) {
        if (response?.data.error == 'duplicate element') {
            state.account.settingsEmailForm.errorMessage = i18n.global.t(
                'Client_settings_settingscontroller_another_acc'
            )
        } else {
            state.account.settingsEmailForm.errorMessage = i18n.global.t(
                'Error_Try_Again'
            )
        }
        state.account.settingsEmailForm.showSuccess = false
        state.account.settingsEmailForm.showError = true
        return false
    }

    state.account.settingsEmailForm.showSuccess = true
    state.account.settingsEmailForm.showError = false
    return true
}

const ChangePassword = async function (data: NewPassword) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/tester/newPassword`, data)
    } catch (error) {
        state.account.settingsPasswordForm.showSuccess = false
        state.account.settingsPasswordForm.showError = true
        return false
    }

    if (response?.status != 200) {
        if (response?.data.error == 'incorrectPassword') {
            state.account.settingsPasswordForm.errorMessage = i18n.global.t(
                'Client_settings_settingscontroller_pass_incorrect'
            )
        } else {
            state.account.settingsPasswordForm.errorMessage = i18n.global.t(
                'Error_Try_Again'
            )
        }
        state.account.settingsPasswordForm.showSuccess = false
        state.account.settingsPasswordForm.showError = true
        return false
    }

    state.account.settingsPasswordForm.showSuccess = true
    state.account.settingsPasswordForm.showError = false
    return true
}

const UpdateNotifications = async function (data: UpdateNotifications) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/tester/updateNotifications`, data)
    } catch (error) {
        state.account.settingsNotificationForm.updatingNotifications = false
        return false
    }

    if (response?.status != 200) {
        state.account.settingsNotificationForm.updatingNotifications = false
        return false
    }

    state.account.settingsNotificationForm.updatingNotifications = false
    return true
}

const resendEmailConfirmation = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/tester/confirmation/resend`)
    } catch (error) {
        return false
    }

    if (response?.status != 200) {
        return false
    }

    return true
}

const getElements = async function (element: string) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/lovs/${element}/elements`)
    } catch (error) {
        return false
    }

    if (response?.status != 200) {
        return false
    }
    if (element == state.details.IdentityDocTypes) {
        state.details.identityDocTypes = response.data
    }
    if (element == state.details.Countries) {
        state.details.countries = response.data
    }

    return true
}

const getElementsWithFilter = async function (payload: any) {
    let response: AxiosResponse | undefined
    console.log(state.details.Provinces)
    try {
        response = await axios.get(
            `/lovs/${state.details.Provinces.id}/elements/filter/${payload}`
        )
    } catch (error) {
        return false
    }

    if (response?.status != 200) {
        return false
    }
    state.details.provinces = response.data
    return true
}


const handleError = (e: any, form: any) => {
    if (e.response.data.duplicatedKey == 'dni') {
        form.errorMessage = 'dni_is_already_in_use'
    } else if (e.response.data.duplicatedKey == 'nick') {
        form.errorMessage = 'nickname_is_already_in_use'
    } else if (e.response.data.duplicatedKey == 'mail') {
        form.errorMessage = 'email_is_already_in_use'
    } else if (e.response.data.payload == 'CIF/NIF not valid') {
        form.errorMessage = "cif_is_not_valid"
    } else if (e.response.data.payload == 'Paypal email not valid') {
        form.errorMessage = "paypal_is_not_valid"
    } else {
        form.errorMessage = "default_error_message"
    }
    Swal.fire(
        'No se ha podido completar la petición',
        form.errorMessage,
        'error'
    )
}

const updatePersonalData = async function (data: any) {
    try {
        const response = await axios.put(`/tester/personal`, data)
        if (response.status !== 200) {
            state.details.personalDataForm.showSuccess = false
            state.details.personalDataForm.showError = true
            throw new Error('Error al actualizar los datos personales.')
        }
        state.details.personalDataForm.showSuccess = true
        state.details.personalDataForm.showError = false
        return true
    } catch (e: any) {
        handleError(e, state.details.personalDataForm)
        return false
    }
}

const updateAddressData = async function (data: any) {
    try {
        const response = await axios.put(`/tester/address`, data)
        if (response.status !== 200) {
            state.details.addressDataForm.showSuccess = false
            state.details.addressDataForm.showError = true
            throw new Error('Error al actualizar los datos de dirección.')
        }
        state.details.addressDataForm.showSuccess = true
        state.details.addressDataForm.showError = false
        return true
    } catch (e: any) {
        handleError(e, state.details.addressDataForm)
        return false
    }
}

const updateProfileImage = async function (data: any) {
    let response: AxiosResponse | undefined
    try {
        response = await axios.put(`/tester/profileImage`, data, {
            headers: {
                accept: 'application/json',
                'Content-Type': `multipart/form-data`,
            },
        })
    } catch (error) {
        return false
    }
    if (response?.status != 200) {
        return false
    }
    return true
}


const validateUniqueMobile = async function (data: ValidateUniqueMobile) {
    try {
        await axios.post(`/tester/confirmMobile`, data)
        state.uniqueValidationFormData.mobileData.input.showError = false
        return true
    } catch (error: any) {
        state.uniqueValidationFormData.mobileData.input.showError = true
        if (error.response && error.response.data && error.response.data.error === 'registrationMobileFraud') {
            state.uniqueValidationFormData.mobileData.input.errorCode = 'registration_mobile_fraud'
        } else {
            state.uniqueValidationFormData.mobileData.input.errorCode = 'default_error_message'
        }
        return false
    }
}

const verifyUniqueMobileCode = async function (userId: string, data: any) {
    try {
        await axios.post(`/tester/${userId}/verifyMobileCode`, data)
    } catch (e: any) {
        if (e.response.data.payload == 'Code not correct') {
            state.uniqueValidationFormData.mobileData.code.errorCode = 'code_not_correct'
        } else {
            state.uniqueValidationFormData.mobileData.code.errorCode = 'default_error_message'
        }
        state.uniqueValidationFormData.mobileData.code.showError = true
        return false
    }
    state.uniqueValidationFormData.mobileData.code.showError = false
    return true
}

const validateUniqueEmail = async function (data: ValidateUniqueEmail) {
    try {
        await axios.post(`/tester/confirmEmail`, data)
        state.uniqueValidationFormData.emailData.input.showError = false
        return true
    } catch (error: any) {
        state.uniqueValidationFormData.emailData.input.showError = true
        state.uniqueValidationFormData.emailData.input.errorCode = 'default_error_message'
        return false
    }
}

const verifyUniqueEmailCode = async function (userId: string, data: any) {
    try {
        await axios.post(`/tester/${userId}/verifyEmailCode`, data)
    } catch (e: any) {
        if (e.response.data.payload == 'Code not correct') {
            state.uniqueValidationFormData.emailData.code.errorCode = 'code_not_correct'
        } else {
            state.uniqueValidationFormData.emailData.code.errorCode = 'default_error_message'
        }
        state.uniqueValidationFormData.emailData.code.showError = true
        return false
    }
    state.uniqueValidationFormData.emailData.code.showError = false
    return true
}

const confirmTermsConditions = async function (userId: string) {
    try {
        await axios.post(`/tester/${userId}/confirmTermsConditions`)
        return true
    } catch (error: any) {
        return false
    }
}

const newMobile = async function (data: NewMobile) {
    try {
        await axios.post(`/tester/newMobile`, data)
        state.phone.newMobileFormData.showError = false
        state.phone.newMobileFormData.showSuccess = true
        return true
    } catch (error: any) {
        state.phone.newMobileFormData.showError = true
        state.phone.newMobileFormData.showSuccess = false
        if (error.response && error.response.data && error.response.data.error === 'registrationMobileFraud') {
            state.phone.newMobileFormData.errorMessage = 'registration_mobile_fraud'
            return 'registrationMobileFraud'
        } else {
            state.phone.newMobileFormData.errorMessage = 'default_error_message'
            return false
        }
    }
}

const addMobile = async function (data: AddMobile) {
    try {
        await axios.post(`/tester/addMobile`, data)
        state.phone.addMobileFormData.showError = false
        state.phone.addMobileFormData.showSuccess = true
        return true
    } catch (error: any) {
        state.phone.newMobileFormData.showError = true
        state.phone.newMobileFormData.showSuccess = false
        if (error.response && error.response.data && error.response.data.error === 'registrationMobileFraud') {
            state.phone.newMobileFormData.errorMessage = 'registration_mobile_fraud'
            return 'registrationMobileFraud'
        } else {
            state.phone.newMobileFormData.errorMessage = 'default_error_message'
            return false
        }
    }
}

const resendMobileConfirmation = async function () {
    try {
        await axios.post(`/tester/mobileValidation/resend`)
    } catch (error) {
        return false
    }
    return true
}

const validateMobile = async function (userId: string, data: any) {
    try {
        await axios.post(`/tester/${userId}/mobileValidation`, data)
    } catch (e: any) {
        if (e.response.data.payload == 'Code not correct') {
            state.phone.mobileValidationFormData.errorMessage = 'tester_pin_code_error'
        } else {
            state.phone.mobileValidationFormData.errorMessage = 'default_error_message'
        }
        state.phone.mobileValidationFormData.showError = true
        return false
    }
    return true
}

const deleteAccount = async function () {
    try {
        await axios.delete(`/tester`)
    } catch (error) {
        return false
    }
    return true
}

const register2 = async function (data: any) {
    let response: AxiosResponse | undefined
    try {
        response = await axios.put(`/tester`, data)
    } catch (e: any) {
        if (e.response.data.error == 'duplicate element') {
            state.signupStep2FormData.errorMessage = 'nickname_is_already_in_use'
        } else {
            state.signupStep2FormData.errorMessage = 'default_error_message'
        }
        state.signupStep2FormData.showSuccess = false
        state.signupStep2FormData.showError = true
        return false
    }
    if (response?.status != 200) return false
    state.signupStep2FormData.showSuccess = true
    state.signupStep2FormData.showError = false
    return true
}

const uploadImage = async function (data: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/file`, data, {
            headers: {
                accept: 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data`,
            },
        })
    } catch (error) {
        return false
    }

    const id = response?.data.id

    response = await axios.get(`/file/${id}`)

    return response?.data.s3path
}

const getRanking = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get('/tester/ranking')
        state.ranking.ranking = response?.data
        return true
    } catch (error) {
        return false
    }
}
const getTopRanking = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get('/tester/topRanking')
        state.ranking.topRanking = response?.data
        return true
    } catch (error) {
        return false
    }
}

export default {
    state: state,
    ChangeEmail,
    clearMyAccount,
    resendEmailConfirmation,
    ChangePassword,
    UpdateNotifications,
    getElements,
    updatePersonalData,
    updateAddressData,
    updateProfileImage,
    getElementsWithFilter,
    clearContactDetails,
    newMobile,
    addMobile,
    resendMobileConfirmation,
    validateMobile,
    clearPhoneNumber,
    deleteAccount,
    uploadImage,
    clearCancelAccount,
    getRanking,
    getTopRanking,
    register2,
    setShowProfileBox,
    validateUniqueEmail,
    validateUniqueMobile,
    verifyUniqueMobileCode,
    verifyUniqueEmailCode,
    confirmTermsConditions
}
