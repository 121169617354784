import { reactive, readonly } from 'vue'
import { AxiosResponse } from 'axios'
import axios from '@/config/axiosConfig'

const state = reactive({
    whatState: {
        posts: [],
        count: null,
        page: 0,
        pages: null,
        pageSize: 6,
        maxPages: null,
        commentsCount: {},
        loading: true,
        loadingComments: true,
    },
})

const getWhatPosts = async function (page: number) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/what/page/${page}/pageSize/${state.whatState.pageSize}`)
    } catch (e) {
        return false
    }

    if (response?.status != 200){
        state.whatState.loading = false
        return false
    }
    state.whatState.count = response?.data.count
    state.whatState.page = response?.data.page
    state.whatState.pageSize = response?.data.pageSize
    state.whatState.maxPages = response?.data.maxPages
    state.whatState.posts = state.whatState.posts.concat(
        response?.data.objects
    )
    state.whatState.loading = false
    return true
}

const getWhatPostsCommentsCount = async function (postIds: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/whatComments/count`, postIds)
    } catch (e) {
        return false
    }

    if (response?.status != 200) return

    state.whatState.commentsCount = response?.data
    return response?.data
}

const getWhatPost = async function (postId: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/what/${postId}`)
    } catch (e) {
        return false
    }

    if (response?.status != 200) return false
    return response?.data
}

const getWhatPostComments = async function (id: any, page: any, pageSize: any) {
    state.whatState.loadingComments = true
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(
            `/whatComments/${id}/page/${page}/pageSize/${pageSize}`
        )
    } catch (e) {
        return false
    }

    if (response?.status != 200) return false

    state.whatState.loadingComments = false
    return response?.data
}

const createWhatComment = async function (id: any, data: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/whatComments/${id}`, data)
    } catch (e) {
        return false
    }

    if (response?.status != 200) return false
}

const rateWhatCommentPositive = async function (id: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/whatComments/${id}/positive`)
    } catch (e) {
        return false
    }

    if (response?.status != 200) return false
}

const rateWhatCommentNegative = async function (id: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/whatComments/${id}/negative`)
    } catch (e) {
        return false
    }

    if (response?.status != 200) return false
}
const replyToComment = async function (id: any, data: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/whatComments/${id}`, data)
    } catch (e) {
        return false
    }

    if (response?.status != 200) return false
}

export default {
    state: readonly(state),
    getWhatPosts,
    getWhatPostsCommentsCount,
    getWhatPost,
    getWhatPostComments,
    createWhatComment,
    rateWhatCommentPositive,
    rateWhatCommentNegative,
    replyToComment,
}
