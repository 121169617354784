
import { defineComponent, inject, onBeforeMount, ref } from 'vue'
import InfoWatDescription from '@/components/InfoWatDescription.vue'
import InfoWatGet from '@/components/InfoWatGet.vue'
import InfoWatStatus from '@/components/InfoWatStatus.vue'
import InfoWatPrivileges from '@/components/InfoWatPrivileges.vue'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
    components: {
        InfoWatDescription,
        InfoWatGet,
        InfoWatStatus,
        InfoWatPrivileges,
    },
    setup() {
        const recordStore: any = inject('recordStore')
        const selectedOption = ref('InfoWatDescription')
        const target = ref(null)
        onClickOutside(target, (event: any) => {
            if (
                event.target !== null &&
                event.target.id !== 'infoPointsActivator'
            ) {
                recordStore.setShowinfoWatiosWatCoins(false)
            }
        })
        return {
            selectedOption,
            target,
        }
    },
})
