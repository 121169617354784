<template>
    <div id="notificationsLoading">
        <div v-for="x in 5">
            <h2 class="item"></h2>
            <p class="item"></p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
*{
    box-sizing: border-box;
}
.item{
    background: #E6E6E6;
    border-radius: 55px;
    position: relative;
}
.item:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: loading 0.8s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
#notificationsLoading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div{
        padding: 10px;
        background-color: white;
        margin-bottom: 2px;
        width: 100%;
    }
    h2{
        width: 30%;
        padding: 11px;
    }
    p{
        width: 100%;
        padding: 8px;
    }
}
</style>