
import { IonPage, IonButtons, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent } from '@ionic/vue';
import { defineComponent } from 'vue'

export default defineComponent({
    components: { IonPage, IonButtons, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent },
    props : {
        pageTitle: {
            type    : String,
            required: true
        }
    },
})
