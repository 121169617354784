import { reactive, readonly } from 'vue'
import { AxiosResponse } from 'axios'
import axios from '@/config/axiosConfig'
import { Device } from '@ionic-native/device'

const state = reactive({
    notifications: {
        count: 0,
        maxPages: 0,
        objects: [],
        page: 0,
        pageSize: 0,
        pages: 0
    },
    unseen: 0,
    showNotifications: false,
    loading: false
})

const registerFirebase = async (firebaseToken: string) => {
    try {
        const response: AxiosResponse | undefined = await axios.post(
            `/appToken`,
            {
                appToken: firebaseToken,
                deviceToken: Device.uuid,
                type: 'ANDROID', //Device.platform,
                Model: Device.model,
                OSversion: Device.version,
            }
        )
        return response
    } catch (err) {
        return null
    }
}

const getNotifications = async (page: any, pageSize: any) => {
    let response: AxiosResponse | undefined
    state.loading = true
    try {
        response = await axios.get(
            `/appNotifications/page/${page}/pageSize/${pageSize}`
        )
    } catch (e) {
        return false
    }

    if (response?.status != 200) return

    state.notifications = response?.data.notifications
    state.unseen = response?.data.unseen
    state.loading = false
}

const markNotificationsAsSeen = async (notificationIds: number[]) => {
    try {
        await axios.post(`/appNotifications/seen`, {
            idsToSee: notificationIds,
        })
        await getNotifications(0, 10)
    } catch (e) {
        console.log(e)
    }
}

const markTestNotificationAsSeen = async (testId: number) => {
    try {
        await axios.post(`/appNotifications/${testId}/seen`, {})
        await getNotifications(0, 10)
    } catch (e) {
        console.log(e)
    }
}

const showNotifications = async (val: boolean) => {
    state.showNotifications = val
    if (!val) {
        if (state.unseen > 0) {
            await markNotificationsAsSeen(
                state.notifications?.objects
                    .filter(
                        (n: any) =>
                            !n.seen
                    )
                    .map((n: any) => n.id)
            )
        }
    }
    // Set a delay to avoid Vue warnings.
    /* await new Promise( ( res, rej ) => setTimeout( ( ) => res( null ), 200 ) ) */
}

export default {
    state: readonly(state),
    getNotifications,
    showNotifications,
    markNotificationsAsSeen,
    markTestNotificationAsSeen,
    registerFirebase,
}
