import ApiService from '../../../commonvue/src/services/api.service'
import authStore from '@/store/auth.store';
import { Capacitor } from '@capacitor/core'

ApiService.setConfig( {
  baseURL: process.env.VUE_APP_API_URL,
  token: () => { return Capacitor.isNativePlatform() ? authStore.state.appToken : authStore.state.authToken },
  interceptors: [
    {
      type: 'request',
      success_cb: (config: any) => config,
      error_cb: (error: any) => Promise.reject( error )
    },
    {
      type: 'response',
      success_cb: (response: any)  => {
        return response;
      },
      error_cb:   (error: any)     => {
        if(error.response.status == 401){
          // close session and exit to login
        }
        return Promise.reject(error);
      },
    }
  ]
} );



export default ApiService;
