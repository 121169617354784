<template>
    <div id="infoWatPrivilegesContainer">
        <h1>{{ $t('Testers_shared_karma_privileges') }}</h1>
        <div id="decorationDiv"></div>
        <p>
            {{
                $t('Testers_shared_karma_explanation_privileges')
            }}
        </p>
        <table>
            <thead>
                <tr>
                    <th class="privileges">{{ $t('Testers_shared_karma_explanation_privileges1') }}</th>
                    <th class="padawat">{{ 'PádaWAT' }}</th>
                    <th class="watios">{{ 'WATpro' }}</th>
                    <th class="watguru">{{ 'WATguru' }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {{ $t('Testers_shared_karma_explanation_level_requiered') }}
                    </td>
                    <td>{{ $t('Testers_shared_karma_explanation_level') }} <br> 1-10</td>
                    <td>{{ $t('Testers_shared_karma_explanation_level') }} <br> 11-20</td>
                    <td>{{ $t('Testers_shared_karma_explanation_level') }} <br> +21</td>
                </tr>
                <tr class="grey">
                    <td>{{ $t('Testers_shared_karma_bonification') }}</td>
                    <td>-</td>
                    <td>+5%</td>
                    <td>+10%</td>
                </tr>
                <tr>
                    <td>
                        {{
                            $t('Testers_shared_karma_explanation_modal_test_priority')
                        }}
                    </td>
                    <td>{{ $t('Testers_shared_karma_explanation_modal_no') }}</td>
                    <td>{{ $t('Testers_shared_karma_explanation_modal_si') }}</td>
                    <td>{{ $t('Testers_shared_karma_explanation_modal_si') }}</td>
                </tr>
                <tr class="grey">
                    <td>{{ $t('Testers_shared_karma_explanation_modal_number_invitations') }}</td>
                    <td>10</td>
                    <td>20</td>
                    <td>30</td>
                </tr>
                <tr>
                    <td>{{ $t('Testers_shared_karma_explanation_modal_exclusive_test') }}</td>
                    <td>{{ $t('Testers_shared_karma_explanation_modal_no') }}</td>
                    <td>{{ $t('Testers_shared_karma_explanation_modal_si') }}</td>
                    <td>{{ $t('Testers_shared_karma_explanation_modal_si') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
* {
    box-sizing: border-box;
}
#infoWatPrivilegesContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    > h1 {
        margin: 0px;
        font-family: 'Bariol';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #000000;
    }
    #decorationDiv {
        background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
        height: 6px;
        width: 78px;
        margin-top: 10px;
    }
    > p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #626262;
    }
    > table {
        background-color: white;
        > thead {
            > tr {
                > th {
                    color: rgb(118, 134, 151);
                    text-align: start;
                    font-size: 10px;
                    font-family: 'Bariol';
                    &.privileges {
                        width: 60%;
                    }
                    &.padawat {
                        width: 13%;
                    }
                    &.watpro {
                        width: 13%;
                    }
                    &.watguru {
                        width: 13%;
                    }
                }
            }
        }
        > tbody {
            > tr {
                &.grey {
                    background-color: rgb(245, 245, 245);
                }
                > td {
                    color: rgb(173, 163, 173);
                    font-size: 9px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }
    }
}
//desktop
@media screen and (min-width: 1000px) {
    #infoWatPrivilegesContainer {
        > h1 {
            font-size: 33px;
        }
        > p {
            font-size: 18px;
        }
        > table {
            > thead {
                > tr {
                    > th {
                        font-size: 20px;
                    }
                }
            }
            > tbody {
                > tr {
                    > td {
                        font-size: 14px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}
</style>