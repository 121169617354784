
import { defineComponent, ref, computed, ComputedRef } from "vue";
import * as yup from "yup";
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import i18n from '@/i18n'
import { AxiosResponse } from "axios";
import axios from "@/config/axiosConfig";

export default defineComponent({
  emits: ['closeContactModal'],
  setup() {
    const showError = ref(false);
    const showSuccess = ref(false);
    const loading = ref(false);
    const submited = ref(false);
    const subjectIsSelected = ref(false);
    const messageIsSelected = ref(false);

    const { errorMessage: subjectError, value: subject } = useField<string>(
      "subject", yup.string().required(i18n.global.t("FIELD_REQUIRED"))
    );
    const { errorMessage: messageError, value: message } = useField<string>(
      "message", yup.string().required(i18n.global.t("FIELD_REQUIRED"))
    );

    const formValid: ComputedRef<boolean> = computed(
      (): boolean =>
        !!subject.value && !subjectError.value &&
        !!message.value && !messageError.value
    );

    const contact = async (data: any) => {
      let response: AxiosResponse | undefined;
      try {
        response = await axios.post(`/tester/contact`, data);
      } catch (error) {
        return false;
      }
      if (response?.status != 200) return false;
      return true;
    };

    const contactSubmit = async () => {
      if (!formValid.value){
        return;
      } 
      loading.value = true;
      const data = {
        title: subject.value,
        body: message.value,
      };
      const succes = await contact(data);
      if (!succes) {
        showSuccess.value = false;
        showError.value = true;
        submited.value = true;
        loading.value = false;
        return;
        
      }
      showSuccess.value = true;
      showError.value = false;
      submited.value = true;
      loading.value = false;
      
    };

    const closeContactModal =()=>{
      submited.value = false;
      showError.value = false;
      showSuccess.value = false;
      subject.value = '';
      message.value = '';
    }

    return {
      subjectError,
      subject,
      messageError,
      message,
      showError,
      showSuccess,
      loading,
      contactSubmit,
      subjectIsSelected,
      messageIsSelected,
      submited,
      closeContactModal
    };
  },
});
