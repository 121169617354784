import ApiService from '../services/api.service'
import { VueCookieNext } from 'vue-cookie-next'

const getters = {
  isAuthenticatedTester(){
    if(process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS') return false
    else if(process.env.VUE_APP_ENVIROMENT == 'WAT_TESTERS') return true
    else if(process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION') {
      if(VueCookieNext.getCookie('authToken')) return true
    }
    return false
  },
  isVisitorTester(){
    // WAT_EXECUTION -> conditional
    if(process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS') return false
    else if(process.env.VUE_APP_ENVIROMENT == 'WAT_TESTERS') return false
    else if(process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION') {
      if(VueCookieNext.getCookie('testerVisitorToken')) return true
    }
    return false
  },
  testerVisitorToken(){
    if(process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS') return VueCookieNext.getCookie('testerVisitorToken')
    else if(process.env.VUE_APP_ENVIROMENT == 'WAT_TESTERS') return undefined
    else if(process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION') return VueCookieNext.getCookie('testerVisitorToken')

  }
}
interface HEADERS {
  'Accept-Language': String
  authToken?: String
  tesVisToken?: String
}
const actions = {
  async FETCH_TEST_INFO(context: any, payload: any) {
      let endPoint = ''
      let headers: HEADERS = {'Accept-Language':payload.lang!=undefined?payload.lang:"es"};


      if (payload.executionType == "TEST"){
        headers.authToken = ApiService.getConfig().token()
        endPoint = '/execution/test/'
      }
      if (payload.executionType == "TEST_PUBLIC"){
        headers.tesVisToken = ApiService.getConfig().testerVisitorToken()
        endPoint = '/execution/test/public/'
      }
      if (payload.executionType == "PREVIEW_PUBLIC"){
        headers.tesVisToken = ApiService.getConfig().testerVisitorToken()
        endPoint = '/execution/client/public/'
      }

      return ApiService
        .get(endPoint + payload.testId, null, {headers:headers})
  },
  async START_TEST_EXEC(context: any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/execution' + (isNaN(payload.testId) ? '/public/' : '/') + payload.testId,payload.data,headers)
  },
  async START_PREVIEW_EXEC(context: any, payload: any) {
      let headers = {headers:{'visToken': ApiService.getConfig().testerVisitorToken()}}
      return ApiService
        .post('/execution/' + payload.testId +'/publicPreview',payload.data,headers)
  },
  async FETCH_TEST_EXECUTION(context: any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/execution' + (isNaN(payload.testId) ? '/public/' : '/') + payload.testId + '/continue',payload.data,headers)
  },
  async ADD_TEST_SEGMENTATION(context: any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/execution/' + payload.testId + '/publicSegmentation',payload.data,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async FETCH_NEXT_QUESTION_BLOCK(context:any, payload:any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .get('/execution'+ (isNaN(payload.testId) ? '/public/': '/') + payload.testId +'/nextQuestionBlock',null,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async ADD_TEST_QUESTION(context:any, payload:any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/execution/'+(isNaN(payload.testId) ? 'public/' : 'test/') + payload.testId + '/task/' + payload.taskNum + '/question/' + payload.questionId, payload.data,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async ADD_TEST_LANGUAGE(context:any, payload:any){
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .put('/execution'+(isNaN(payload.testId)?'/public/':'/') + payload.testId + '/language',{language:payload.lang},headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async SET_EMBEDDED_TRAP(context:any, payload:any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/execution'+ (isNaN(payload.testId)?'/public/':'/') +payload.testId+'/trap',payload.data,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async SET_EMBEDDED_QUOTA(context:any, payload:any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/test/embedded/'+payload.testId+'/' +(isNaN(payload.testId)? 'publicQuotas' : 'quotas'),null,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async SET_EMBEDDED_QUALITY_KILLER(context:any, payload:any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/test/embedded/'+payload.testId+'/' +(isNaN(payload.testId)? 'publicQualityKiller' : 'qualityKiller'),null,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async SET_TASK_VALIDATION(context:any, payload:any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post('/execution/' +(isNaN(payload.testId)? 'public' : 'test')+"/"+payload.testId+"/task/"+payload.taskNum+"/validation",{},headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async DELETE_PREVIOUS_ANSWER(context:any, payload:any) {
    let headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    return ApiService
      .delete('/execution/'+ payload.testId + '/lastPreviewAnswer',null,headers)
  },
  async ADD_USER_DATA(context:any, payload:any) {
    return ApiService
      .put('/userDataConfirmation',payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async FETCH_TESTER_COUNTRY(context: any, payload: any) {
    return ApiService
      .get('https://ipinfo.io/json',null, {
        'withCredentials': false,
        'Access-Control-Allow-Credentials': false,
      })
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async UPLOAD_IMAGE_URL(context:any, payload:any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .postFile('/file',payload.formData,headers)
  },
  getVisitorToken(){
    return ApiService
      .get('/visitorToken',null)
      .then(({data}: any) => {
        if(process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS' || process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION') VueCookieNext.setCookie('testerVisitorToken', data.visitorToken)
        return data
      })
  },
  FETCH_TESTER_DATA(context: any, payload: any) {
    return ApiService
      .get('/tester', null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  async getUserDetails(){
    return ApiService
      .get('/tester', null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  async SAVE_UX_QUANTI_DATA(context: any, payload: any){
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    let path = `/execution/test/${payload.testId}/task/${payload.taskNum}/uxQuantiData`
    if(isNaN(payload.testId)) path = `/execution/public/test/${payload.testId}/task/${payload.taskNum}/uxQuantiData`
    return ApiService
    .post(path,payload.data, headers)
    .then(({data}: any) => {
      return data
    })
    .catch(({response}: any)  => {
      return response
    })
  },
  async SAVE_CLICK_TEST_DATA(context: any, payload: any){
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    let path = `/execution/test/${payload.testId}/task/${payload.taskNum}/clickTestData`
    if(isNaN(payload.testId)) path = `/execution/public/test/${payload.testId}/task/${payload.taskNum}/clickTestData`
    return ApiService
    .post(path,payload.data, headers)
    .then(({data}: any) => {
      return data
    })
    .catch(({response}: any)  => {
      return response
    })
  },
  async SET_TIMEOUT(context:any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    let path = `/execution/test/${payload.testId}/timeout`
    if(isNaN(payload.testId)) path = `/execution/public/test/${payload.testId}/timeout`
    return ApiService
      .post(path,payload.data, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },

  async FETCH_TEST_FOCUS_CALENDAR(context: any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .get(`/execution/${isNaN(payload.testId) ? 'public' : 'test'}/${payload.testId}/watfocus`,payload.data, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  async SAVE_WAT_FOCUS_TEST_EXEC_CONNECTIVITY(context: any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post(`/execution/${isNaN(payload.testId) ? 'public' : 'test'}/${payload.testId}/watfocus/connectivity`,payload.data, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  async SAVE_WAT_FOCUS_TEST_EXEC_CALENDAR(context: any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post(`/execution/${isNaN(payload.testId) ? 'public' : 'test'}/${payload.testId}/watfocus/calendar`,payload.data, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  async SAVE_WAT_FOCUS_TESTER(context: any, payload: any) {
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    return ApiService
      .post(`/execution/public/${payload.testId}/watfocus/user`,payload.data, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  }

}

export default{
  actions,
  getters
}
