import { reactive, readonly }           from 'vue';
import { AxiosResponse }                from 'axios'
import axios                            from '@/config/axiosConfig'

const state = reactive( {
  howWorkData  : { }
} );

const getHowWorkData = async function( ) {
  let response: AxiosResponse | undefined

  try {
    response = await axios.get( `/tester/howItWorksData` )
  } catch ( e ) {
    return false
  }

  if ( response?.status != 200 ) return

  state.howWorkData = response?.data
}

export default { state: readonly( state ), getHowWorkData };
