import { reactive } from 'vue'
import { AxiosResponse } from 'axios'
import axios from '@/config/axiosConfig'

const state = reactive({
    testsState: {
        count: null,
        filterType: 'ALL',
        filterDisponibility: 'ALL',
        firstLoading: true,
        loading: true,
        page: null,
        pageSize: null,
        maxPages: null,
        test: {},
        tests: [],
        watFocusList:[]
    }
})

const getWatFocusList = async function () {
    let response: AxiosResponse | undefined
    try{
        response = await axios.post(`/watFocusForWall`)
    } catch (e) {
        state.testsState.loading = false
        return false
    }

    if (response?.status != 200) {
        state.testsState.loading = false
        return false
    }
    state.testsState.watFocusList = response?.data
    return true
}
const getWatFocus = async function (id: any, slotHash: any) {
    let response: AxiosResponse | undefined
    try{
        response = await axios.get(`/watFocus/${id}/slot/${slotHash}`)
    } catch (e) {
        state.testsState.loading = false
        return Promise.reject(e)
    }

    if (response?.status != 200) {
        state.testsState.loading = false
        return false
    }
    return Promise.resolve(response?.data)
}

const getTests = async function (page: any, data: any = {}, mobile: boolean = false) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/wallForApp/${page}`, data)
    } catch (e) {
        state.testsState.loading = false
        return false
    }

    if (response?.status != 200) {
        state.testsState.loading = false
        return false
    }

    state.testsState.count = response?.data.count
    state.testsState.page = response?.data.page
    state.testsState.pageSize = response?.data.pageSize
    state.testsState.maxPages = response?.data.maxPages
    if (mobile) {
        state.testsState.tests = state.testsState.tests.concat(
            response?.data.tests
        )
        state.testsState.loading = false
        return true
    }
    state.testsState.tests = response?.data.tests
    state.testsState.loading = false
    return true
}

export default { state: state, getTests, getWatFocusList, getWatFocus }
