import { reactive, readonly } from 'vue'
import { AxiosResponse } from 'axios'
import axios from '@/config/axiosConfig'

const state = reactive({
    recordState: {
        userPoints: {
            points: [],
            count: null,
            page: null,
            pages: null,
            pageSize: null,
            maxPages: null,
        },
        loading: true,
        showinfoWatiosWatCoins: false,
    },
})

const setShowinfoWatiosWatCoins = (value: boolean) => {
    state.recordState.showinfoWatiosWatCoins = value
}

const getUserPoints = async function (page: number, mobile: boolean = false) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/points/history/${page}`)
    } catch (error) {
        return false
    }

    if (response?.status != 200) {
        state.recordState.loading = false
        return false
    }

    state.recordState.userPoints.count = response?.data.count
    state.recordState.userPoints.page = response?.data.page
    state.recordState.userPoints.pageSize = response?.data.pageSize
    state.recordState.userPoints.maxPages = response?.data.maxPages

    if (mobile) {
        state.recordState.userPoints.points = state.recordState.userPoints.points.concat(
            response?.data.objects
        )
        state.recordState.loading = false
        return true
    }
    state.recordState.userPoints.points = response?.data.objects
    state.recordState.loading = false
    return true
}

const deleteUserData = function () {
    state.recordState = {
        userPoints: {
            points: [],
            count: null,
            page: null,
            pages: null,
            pageSize: null,
            maxPages: null,
        },
        loading: false,
        showinfoWatiosWatCoins: false,
    }
}

export default {
    state: state,
    getUserPoints,
    deleteUserData,
    setShowinfoWatiosWatCoins,
}
