
import { defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
    emits: ['openContactModal'],
    setup() {
        const currentDate = new Date();
        const androidDownloadLink = 'https://play.google.com/store/apps/details?id=com.byv.inlacou.wat&hl=es';
        const iosDownloadLink= 'https://itunes.apple.com/us/app/we-are-testers/id1096049567?l=es&ls=1&mt=8';
        const getYear = () =>{
            return moment(currentDate).year();
        }
        return{
            currentDate,
            getYear,
            androidDownloadLink,
            iosDownloadLink
        }
    },
})
