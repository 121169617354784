import { AxiosResponse } from "axios";
import axios from "@/config/axiosConfig";

const notifyCompletion = async function(payload: any) {
  let headers = {};
  try {
    const response = await axios.post(payload.url, payload,headers)
    return response.data
  } catch (error) {
    throw error;
  }
}

const storeTestRecordings = async function(payload: any) {
  var formData = new FormData();
  formData.append("file", payload.file);
  let headers = {
    onUploadProgress: payload.onUploadProgress,
    headers:{
      'Content-Type': 'multipart/form-data',
    }
  }

  try {
    const response = await axios.post('/uxVideo',formData,headers)
    return response.data
  } catch (error) {
    throw error;
  }
  
}

const storeTestAudio = async function(payload: any) {
  var formData = new FormData();
  formData.append("file", payload.file);
  let headers = {
    onUploadProgress: payload.onUploadProgress,
    headers:{
      'Content-Type': 'multipart/form-data',
    }
  }

  try {
    const response = await axios.post('/file',formData,headers)
    return response.data
  } catch (error) {
    throw error;
  }
  
}

const validateDataCompletion = async function(payload: any) {
  let headers = {};

  try {
    const response = await axios.get(payload.url)
    return response.data
  } catch (error) {
    throw error;
  }
  
}

export default{
  storeTestAudio,
  notifyCompletion,
  storeTestRecordings,
  validateDataCompletion
}
