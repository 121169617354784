<template>
    <Pagination
        v-model="localModelValue"
        :records="records"
        :per-page="recordsPerPage"
        @paginate="changePage($event)"
        :options="options"
    />
</template>

<script>
import Pagination from 'v-pagination-3'

export default {
    name: 'PaginationWat',
    components: {
        Pagination,
    },
    props: {
        modelValue: Number,
        records: {
            type: Number,
            default: 1,
        },
        recordsPerPage:{
            type: Number,
            default: 6
        },
        chunk: {
            type: Number,
            default: 10,
        },
        chunksNavigation: {
            type: String,
            default: 'fixed',
        },
    },
    data() {
        return {
            options: {
                chunk: this.chunk,
                chunksNavigation: this.chunksNavigation,
            },
        }
    },
    computed: {
        localModelValue: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            },
        },
    },
    emits: ['update:modelValue', 'paginate'],
    methods: {
        changePage(page) {
            this.$emit('update:modelValue', page)
            this.$emit('paginate')
        },
    },
}
</script>

<style lang="scss">
.VuePagination {
    &__pagination {
        display: flex;
        align-items: center;
    }
    &__count {
        display: none;
    }
    ul {
        width: fit-content;
        margin: 0px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    li {
        list-style: none;
        margin: 0 3px;
    }
    .active button {
        border: 1px solid #8134ff !important;
        background-color: white;
        color: #8134ff;
    }
    button {
        font-size: 14px;
        font-family: 'Bariol';
        width: 32px;
        height: 32px;
        color: #1f1f1f;
        background-color: white;
    }
}
</style>
