<template>
    <div id="infoWatDescriptionContainer">
        <h1>{{ $t('Testers_shared_karma_explanation_modal_what_is_watios_watcoins') }}</h1>
        <div id="decorationDiv"></div>
        <p>
            {{
                $t('Testers_shared_karma_explanation_modal_2_points')
            }}
        </p>
        <p>
            <span>{{ $t('Testers_pointsHistory_index_watios') }}</span
            >{{ $t('Testers_shared_karma_explanation_modal_points1') }}
        </p>
        <p>
            <span>{{ $t('Testers_pointsHistory_index_watcoins') }}</span
            >{{
                $t('Testers_shared_karma_explanation_exchange_points')
            }}
        </p>
    </div>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
* {
    box-sizing: border-box;
}
//mobile
#infoWatDescriptionContainer {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    > h1 {
        margin: 0px;
        font-family: 'Bariol';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #000000;
    }
    #decorationDiv {
        background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
        height: 6px;
        width: 78px;
        margin-top: 10px;
    }
    > p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #626262;
        > span {
            font-weight: 700;
        }
    }
}
//desktop
@media screen and (min-width: 1000px) {
    #infoWatDescriptionContainer {
        > h1 {
            font-size: 33px;
        }
        > p {
            font-size: 18px;
        }
    }
}
</style>