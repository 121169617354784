import { reactive }           from 'vue';

const state = reactive({
    showInviteFriendsModal: false
})

const showInviteFriends = (val: boolean)=>{
    state.showInviteFriendsModal = val
}

export default {state, showInviteFriends}