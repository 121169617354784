import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { IonicVue, getPlatforms } from '@ionic/vue'
import axios from '@/config/axiosConfig'
import moment from 'moment-timezone';
import { map } from 'lodash';
import '@/theme/'
import 'animate.css'

/* Store */
import store from '@/store'
import authState from '@/store/auth.store'
import lovStore from './store/lov.store'
import testsStore from '@/store/tests.store'
import watuxStore from '@/store/watux.store'
import notificationsStore from '@/store/notifications.store'
import howStore from '@/store/how.store'
import whatStore from '@/store/what.store'
import recordStore from '@/store/record.store'
import exchangeStore from '@/store/exchange.store'
import configurationStore from '@/store/configuration.store'
import inviteFriendsStore from '@/store/inviteFriends.store'
/* Global Components */
import MasterLayout from '@/components/layouts/MasterLayout.vue'
import GoBackLayout from '@/components/layouts/GoBackLayout.vue'
import i18n from '@/i18n'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import {SplashScreen} from '@capacitor/splash-screen';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
Bugsnag.start({
    apiKey: '5e7c5178380aa8da2771414db7d77227',
    plugins: [new BugsnagPluginVue()],
})
const bugsnagVue: any = Bugsnag.getPlugin('vue')

import OneSignal from 'onesignal-cordova-plugin'

const goTo = (url: any) => {
    router.push({ path: url })
}
const OneSignalInit = async function (): Promise<void> {
    OneSignal.setAppId('15129d68-3416-4501-bec3-c5f7631d98bb')
    let playerId: string
    OneSignal.addSubscriptionObserver(function (state) {
        console.log("addSubscriptionObserver")
        if (!state.from.isSubscribed && state.to.isSubscribed) {
            //deviceId playerId
            if (state.to.userId) {
                console.log("Setting playerId "+state.to.userId)
                playerId = state.to.userId
            }
        }
    })
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        console.log('User accepted notifications: ' + accepted)
    })
    OneSignal.getDeviceState(async function (state) {
        console.log("getDeviceState")
        if (state.userId) {
            playerId = state.userId
        }
        const deviceInfo = (window as any).device
        const appToken = {
            oneSignalDeviceToken: playerId,
            oneSignalDeviceModel:
                deviceInfo.manufacturer + ' ' + deviceInfo.model,
            oneSignalDevicePlatform: deviceInfo.platform,
            oneSignalDeviceVersion: deviceInfo.version,
        }
        console.log(appToken)
        try {
            const response: any = await axios.post(
                '/oneSignalAppToken',
                appToken
            )
        } catch (error) {
            console.log("Error when gettingDeviceState "+error)
        }
    })
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
        var notificationData: any = jsonData
        const deepLink = JSON.stringify(
            notificationData.notification.additionalData.deepLink
        )
        goTo(deepLink.replaceAll('"', ''))
    })
}
document.addEventListener('deviceready', OneSignalInit, false)

const app = createApp(App)
    .use(IonicVue, {
        animated: false,
    })
    .use(router)
    .use(i18n)
    .use(store)
    .use(bugsnagVue)
    .use(Antd)
    .provide('axios', axios)
    .provide('authState', authState)
    .provide('lovStore', lovStore)
    .provide('testsStore', testsStore)
    .provide('notificationsStore', notificationsStore)
    .provide('howStore', howStore)
    .provide('whatStore', whatStore)
    .provide('watuxStore', watuxStore)
    .provide('recordStore', recordStore)
    .provide('exchangeStore', exchangeStore)
    .provide('configurationStore', configurationStore)
    .provide('inviteFriendsStore', inviteFriendsStore)
    .component('master-layout', MasterLayout)
    .component('goBack-layout', GoBackLayout)


app.config.globalProperties.$filters = {
    formatDate(date:Date, format:string){
        if(date == undefined) return "-"
        return moment(date).format(format);
    },
    formatDateFull(date: any){
        if (date == undefined) {
          return "-"
        }
        return moment(date).format("DD/MM/YY HH:mm");
    },
    formatDateFocusBox(date: any){
        moment.locale("es")
        if(date == undefined) return "-"
        return  moment(date).format('dddd D [de] MMMM [a las] HH:mm')
    },
    formatMedia(media: any){
        return map(media, (path: any) => {
          return { src: path }
        })
      },
}

const showSplash = async ()=>{
    await SplashScreen.show();
}

router.isReady().then(() => {
    showSplash();
    app.mount('#app')
})

export default app
